<template>
  <v-card
    class="pa-0"
    color="grey lighten-4"
    flat
    tile
    :loading="!session || loading? 'primary' : false"
    :class="$style.minHeight"
    :key="`hitting-summary`"
  >
    <v-container>
      <v-row
        align="center"
        v-if="awayTeam && awayStats.length && session.mightHaveTwoTeams"
      >
        <v-col>
          <v-divider />
        </v-col>
        <v-col cols="auto">
          <team-logo-url
            :url="awayTeam.logo"
            :name="awayTeam.displayName"
            :short-code="awayTeam.ShortCode"
            class="px-2"
            displayName
          ></team-logo-url>
        </v-col>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-container v-if="awayTeam && awayStats.length">
        <player-results-stats
          class="mb-4"
          v-for="(item, key) in awayStats"
          :key="key"
          :playerData="item.playerData"
          :stats="item.plateAppearances"
          :summary="item.summary"
          :unitsSystem="selectedUnitsSystem"
          :report="report"
          :filters="dataFilters"
          @horizontalScroll="syncTableScroll"
        ></player-results-stats>
      </v-container>

      <v-row
        align="center"
        v-if="homeTeam && homeStats.length && session.mightHaveTwoTeams"
      >
        <v-col>
          <v-divider />
        </v-col>
        <v-col cols="auto">
          <team-logo-url
            :url="homeTeam.logo"
            :name="homeTeam.displayName"
            :short-code="homeTeam.ShortCode"
            class="px-2"
            displayName
          ></team-logo-url>
        </v-col>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-container v-if="homeTeam && homeStats.length">
        <player-results-stats
          class="mb-4"
          v-for="(item, key) in homeStats"
          :key="key"
          :playerData="item.playerData"
          :stats="item.plateAppearances"
          :summary="item.summary"
          :unitsSystem="selectedUnitsSystem"
          :report="report"
          :filters="dataFilters"
          @horizontalScroll="syncTableScroll"
        ></player-results-stats>
      </v-container>

      <v-row align="center" v-if="naStats.length && session.mightHaveTwoTeams">
        <v-col>
          <v-divider />
        </v-col>
        <v-col cols="auto">
          <team-logo-url class="px-2" displayName></team-logo-url>
        </v-col>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-container v-if="naStats.length">
        <player-results-stats
          class="mb-4"
          v-for="(item, key) in naStats"
          :key="key"
          :playerData="item.playerData"
          :stats="item.plateAppearances"
          :summary="item.summary"
          :unitsSystem="selectedUnitsSystem"
          :report="report"
          :filters="dataFilters"
          @horizontalScroll="syncTableScroll"
        ></player-results-stats>
      </v-container>
      <no-data v-if="hasNoData" :message="nodata"></no-data>
    </v-container>

    <ExportCsvBtn
      v-if="showExportBtn"
      :sessionOrPlayer="this.session"
      :context="ctx"
      :stats="[...homeStats, ...awayStats, ...naStats]"
      :unitsSystem="selectedUnitsSystem"
      :report="report"
      :filters="dataFilters"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { Providers, Enums } from '@flightscope/baseball-stats';
import { Session } from '@/models/orm/Hierarchy';
import PlayerResultsStats from '../../../components/session/hitting/PlayerResultsStats.vue';
import ResultFilter from '../../../filters/ResultFilter';
import TeamLogoUrl from '../../../components/team/TeamLogoUrl.vue';
import { mapHittingSummaryStats } from '../../../utils/mappers';
import { GenericPitchType } from '@/enums/Generic';
import { convertUnitNoSymbol } from '../../../filters/units';
import FilterStores from '../../../enums/StoreKeys';
import { mapRunnersOnSelectToProvider } from '../../../utils/helpers';
import { SessionType } from '@/store/actions/api';
import { EReportContext } from '../../../enums/reports';
const NoData = () => import('@/components/core/NoData.vue');
const ExportCsvBtn = () => import('@/modules/session/views/ExportCsvBtn.vue');

export default {
  name: 'HittingSummary',

  inject: ['repo'],

  props: {
    session: {
      type: Session,
      required: true,
    },
    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },
  },

  components: {
    PlayerResultsStats,
    TeamLogoUrl,
    NoData,
    ExportCsvBtn,
  },

  data() {
    return {
      loading: false,
      nodata: 'No data found matching the given filter criteria.',
      naPlayers: [],
      homeStats: [],
      awayStats: [],
      naStats: [],
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),
    ctx() {
      return EReportContext.BATTING;
    },
    activeFilters() {
      return this.session.filters[FilterStores.HITTING_SUMMARY];
    },
    hasNoData() {
      return (
        this.session.hasPlayersLoaded
        && this.homeStats.length === 0
        && this.awayStats.length === 0
        && this.naStats.length === 0
      );
    },

    dataFilters() {
      let filters = {
        pitcherIds: this.activeFilters[ResultFilter.Pitchers.key],
        pitchResults: this.activeFilters[ResultFilter.PitchResult.key],
        pitchTypes: this.activeFilters[ResultFilter.PitchType.key],
        batterIds: this.activeFilters[ResultFilter.Batters.key],
        batterHandedness: this.activeFilters[ResultFilter.BatterHandedness.key],
        pitcherHandedness: this.activeFilters[ResultFilter.PitcherHandedness.key],
      };

      if (this.session.hasExtendedTagging || this.session.v2 && this.session.mightHaveScoring) {
        filters.pitchResults = this.activeFilters[ResultFilter.PlayOutcome.key];
      }

      if (this.session.mightHaveScoring) {
        filters = {
          ...filters,
          outs: this.activeFilters[ResultFilter.ScoringOuts.key],
          balls: this.activeFilters[ResultFilter.ScoringBalls.key],
          strikes: this.activeFilters[ResultFilter.ScoringStrikes.key],
          runnersOn: mapRunnersOnSelectToProvider(this.activeFilters[ResultFilter.ScoringRunnersOn.key]),
        };
      }
      return filters;
    },

    homeTeam() {
      return this.session?.homeLineup?.team;
    },

    awayTeam() {
      return this.session?.awayLineup?.team;
    },

    summaryData() {
      if (this.session) {
        return Providers.HittingSummaryDataProvider(this.session.resultsv1, undefined, this.dataFilters);
      }
      return [];
    },

    report() {
      if (this.session) {
        return this.session.resultsv1;
      }
      return [];
    },

    showExportBtn() {
      return this.$can('export', 'Csv')
        && (
          (this.homeTeam && this.homeStats.length)
          || (this.awayTeam && this.awayStats.length)
          || this.naStats.length
        )
    },
  },

  methods: {
    mapSummary(item) {
      return {
        // keep as is
        Hits: item.Hits,
        HomeRuns: item.HomeRuns,
        TotalBases: item.TotalBases,
        GroundBalls: item.GroundBalls,
        Pitches: item.Pitches,
        BattedBalls: item.BattedBalls,
        FairBalls: item.FairBalls,
        LineDrives: item.LineDrives,
        FlyBalls: item.FlyBalls,
        PopUps: item.PopUps,

        // units & fraction digits
        GroundBallRate: convertUnitNoSymbol(item.GroundBallRate, 'NUMBER', this.selectedUnitsSystem.system, {
          noValueFallback: '-',
          fractionalDigits: 1,
        }),
        LineDriveRate: convertUnitNoSymbol(item.LineDriveRate, 'NUMBER', this.selectedUnitsSystem.system, {
          noValueFallback: '-',
          fractionalDigits: 1,
        }),
        FlyBallRate: convertUnitNoSymbol(item.FlyBallRate, 'NUMBER', this.selectedUnitsSystem.system, {
          noValueFallback: '-',
          fractionalDigits: 1,
        }),
        PopUpRate: convertUnitNoSymbol(item.PopUpRate, 'NUMBER', this.selectedUnitsSystem.system, {
          noValueFallback: '-',
          fractionalDigits: 1,
        }),
        PitchesPerPa: convertUnitNoSymbol(item.PitchesPerPa, 'NUMBER', this.selectedUnitsSystem.system, {
          noValueFallback: '-',
          fractionalDigits: 1,
        }),
      };
    },

    mapStats(playerStats) {
      return mapHittingSummaryStats(playerStats, this.selectedUnitsSystem.system, this.session.players)
    },

    prepareData() {
      this.loading = true;

      let homeStats = [];
      let awayStats = [];
      let naStats = [];

      const calculatedStats = this.summaryData;

      for (let index = 0; index < calculatedStats.length; index++) {
        const curr = calculatedStats[index];
        const tagId = parseInt(curr.batterId, 10);
        const { plateAppearances, summary } = curr;

        let dataObj = {
          playerData: null,
          // stats: this.mapStats(pitchTypes),
          plateAppearances: this.mapStats(plateAppearances),
          summary: this.mapSummary(summary),
        };

        if (this.homeTeam) {
          const homePlayer = this.homeTeam.players.find(p => p.id === tagId);

          if (homePlayer) {
            dataObj.playerData = homePlayer;
            homeStats.push(dataObj);
            continue
          }
        }

        if (this.awayTeam) {
          const awayPlayer = this.awayTeam.players.find(p => p.id === tagId);
          if (awayPlayer) {
            dataObj.playerData = awayPlayer;
            awayStats.push(dataObj);
            continue
          }
        }

        const naPlayer = this.PlayerRepo.getDefault(tagId);
        dataObj.playerData = naPlayer;

        naStats.push(dataObj);
      }

      this.homeStats = homeStats;
      this.awayStats = awayStats;
      this.naStats = naStats;

      this.loading = false;
    },

    syncTableScroll(e) {

        const tables = document.querySelectorAll(`.v-data-table__wrapper`);
        tables.forEach((table, idx) => {
            table.scrollLeft = e.target.scrollLeft;
          });

    },
  },

  watch: {
    summaryData: {
      handler: 'prepareData',
      immediate: true,
    },
  },
};
</script>

<style module>
.minHeight {
  min-height: 10em;
}
</style>
